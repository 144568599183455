<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";

export default {
  page: {
    title: "열람 수 조회",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: "열람 수 통계",
      daySeries: [{
        name: '일별 열람 수',
        data: []
      }],
      dayChartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: [],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          }
        },
      },

      timeSeries: [{
        name: '조회수',
        data: []
      }],
      timeChartOptions: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },

        labels: [],
        /*xaxis: {
          type: 'datetime',
        },*/
        yaxis: {
          opposite: true,
          labels: {
            formatter: (value)=> {
              return value.toFixed(0)
            }
          }
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
    };
  },

  computed: {
    ...mapState(loggedInfoObject)
  },

  beforeMount() {
    console.log(this.daySeries)
    this.callTimeGraphData()
    this.callDailyGarphData()
  },

  methods: {
    callTimeGraphData() {
      let url = `/api/mrt/open/graph/vue/${this.logged_info.mart_srl}`
      //let url = `/api/mrt/open/graph/vue/0`

      axios.get(url).then((res) => {
        console.log(res.data)
        this.timeSeries = [{data : res.data.cnt}]
        this.timeChartOptions = {
          ...this.timeChartOptions,
          ...{
            labels: res.data.label
          }
        }
      }).catch(err => console.log(err))
    },

    callDailyGarphData() {
      let url = `/api/mrt/open/graph/vue/daily/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        console.log(res.data)

        this.daySeries = [{data: res.data.cnt}]
        this.dayChartOptions = {
          ...this.dayChartOptions,
          ...{
            xaxis: {
              categories: res.data.label
            }
          }
        }
      }).catch(err => console.log(err))
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">시간별 열람 수</h4>
      </div>
      <div class="card-body">
        <apexchart type="area" height="300" :options="timeChartOptions" :series="timeSeries"></apexchart>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">일별 열람 수</h4>
      </div>
      <div class="card-body">
        <apexchart type="bar" height="300" :options="dayChartOptions" :series="daySeries"></apexchart>
      </div>
    </div>
  </Layout>
</template>
